<template>
  <main class="container my-3 text-justify">
    <div class="row">
      <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 mx-auto">
        <div class="col-12">
          <h2>» Über mich</h2>
          <p class="ps-4 m-0">Geschichtsstunde</p>
        </div>
        <div class="col-12">
          <img
            class="img-fluid mx-auto d-block px-4"
            src="@/assets/mader-jonas.svg"
            alt="mader-jonas"
          />
        </div>
        <div class="col-12 mt-3 px-4">
          <p>
            Der Süss-Sauren bin ich eher zufällig über den Weg gelaufen. Ganz am
            Anfang habe ich nur das Logo und die Gestaltung entworfen. Danach
            durfte ich dieses wunderbare Projekt selbst weiterführen. Mein
            großes Ziel ist es, die Süss-Saure in einem Laden zu verkaufen und
            sie im ganzen Allgäu bekannt zu machen
          </p>
          <p>
            Aber jetzt kommt das wichtigste: Du. Ohne Dich funktioniert diese
            Idee nicht. Ich bin überglücklich über jeden, der die Idee
            unterstützt. Egal wie. <span class="raleway-bold"> Danke Dir.</span>
          </p>
        </div>
        <div class="col-12 text-center mt-4">
          <a
            href="https://www.instagram.com/jonas.mdr"
            class="btn btn-primary shadow rounded-pill  raleway-black"
          >
            » Jonas auf Insta
          </a>
        </div>
      </div>
    </div>
  </main>
</template>

